body.no-js
{
	.hlp-network
	{
		.title-bar
		{
			display: none;
		}
	}
	.menu.dropdown
	{
		a
		{
			&:hover
			{
				background-color: $primary-color;
				color: white;
			}
		}
		> li
		{
			position: relative;
			.menu
			{
				display: inline-block;
				position: absolute;
				right: 2px;
				top: 50%;
				transform: translateY(-50%);
				border-top: 6px solid $primary-color;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				> *
				{
					display: none;
				}
			}
			&:hover
			{
				.menu
				{
					display: block;
					border: none;
					min-width: 12em;
					position: absolute;
					z-index: 999;
					top: 100%;
					left: 0;
					right: auto;
					transform: none;
					box-shadow: 0 0 10px black;
					> *
					{
						display: block;
					}
				}
			}
		}
	}
}

.page-header
{
	// padding: 80px 0;
	// box-shadow: 0 -5px 5px 10px black;
	margin-bottom: rem-calc(40);
	.hlp-network
	{
		.title-bar
		{
			button
			{
				color: white;
			}
		}
	}
	a
	{
		color: white;
	}
	.site-nav
	{
		background-color: #7d0101;
		background-image:
			linear-gradient(to bottom, rgba(#7d0101, 0.5) 0%, rgba(#270404, 0.5) 100%),
			repeating-linear-gradient(to bottom, #181818, #181818 1px, #000 1px, #000 2px),
			;
		// display: flex;
		// flex-flow: row wrap;
		// align-items: flex-end;
	}
	.site-logo
	{
		margin: 5px auto;
		// position: relative;
		// z-index: 1;
		a
		{
			display: block;
		}
		img
		{
			width: 100%;
			max-width: 250px;
		// 	margin-top: -10px;
		// 	margin-bottom: -30px;
		}
	}
	.icon
	{
		width: 13px;
		height: 13px;
		fill: currentColor;
	}
	// nav
	// {
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: space-between;
	// }
	.nav-main,
	.nav-cta
	{
		list-style-type: none;
		margin: 0;
	}
	.nav-main
	{
		// flex: 1 1 auto;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-end;
		justify-content: space-around;
		a
		{
			display: block;
			text-transform: uppercase;
			font-weight: bold;
			padding: 0.25em;
			&:hover
			{
				text-decoration: underline;
			}
		}
	}
	.nav-cta
	{
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		justify-content: space-around;
		margin: 0.5em auto;
		li
		{
			margin: 0 0.5em;
		}
		a
		{
			margin: 1px;
			display: block;
			text-transform: uppercase;
			font-weight: bold;
			font-size: rem-calc(14);
			text-align: center;
			padding: 0.25em 1.5em 0.15em;
			background-color: $primary-color;
			transition: 0.3s ease background-color;
			&:hover
			{
				background-color: darken($primary-color, 10%);
			}
		}
	}
	@include breakpoint(medium)
	{
		.site-logo
		{
			img
			{
				max-width: 300px;
			}
		}
		.nav-main
		{
			flex-flow: row nowrap;
		}
		.nav-cta
		{
			display: block;
			li + li
			{
				margin-top: 0.5em;
			}
		}
	}
	@include breakpoint(large)
	{
		.site-logo
		{
			margin: 5px 10px -25px;
		}
		.nav-main
		{
			flex-flow: row nowrap;
			a
			{
				padding: 0.5em 1em;
				font-size: rem-calc(20);
			}
		}
	}
}