.badge
{
	display: inline-block;
	background-color: #fff;
	line-height: 16px;
	height: 16px;
	width: 16px;
	text-align: center;
	border-radius: 8px;
	font-weight: 900;
	color: black;
	transition: 0.4s ease all;
	&[data-toggle]
	{
		cursor: help;
	}
	&:hover
	{
		background-color: $hlp-light-red;
		box-shadow: 0 0 10px white;
		color: white;
	}
}