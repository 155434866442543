.section__standalone
{
	background-size: cover;
	background-position: 50% 50%;
	height: rem-calc(450);
	display: flex;
	align-items: stretch;
	text-shadow: 0 0 15px rgba(black, 0.5);
	.standalone-games // figure
	{
		flex: 1 0 100%;
		padding: rem-calc(32);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	figcaption
	{
		text-align: left;
		text-shadow:
			0 0 10px rgba(black, 1),
			0 0 20px rgba(black, 1),
			0 0 30px rgba(black, 1);
		h2
		{
			font-weight: 700;
			font-size: rem-calc(36);
			p
			{
				margin: 0;
			}
			strong
			{
				font-weight: 900;
				letter-spacing: 0.075em;
			}
		}
		.button
		{
			text-transform: uppercase;
			font-size: rem-calc(18);
			letter-spacing: 0.05em;
			text-shadow: none;
		}
	}
	@include breakpoint(medium)
	{
		figcaption
		{
			max-width: 320px;
			margin-left: 50%;
		}
	}
}