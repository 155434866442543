hr,
br
{
	&.small
	{
		margin: rem-calc(20) auto;
	}
	&.medium
	{
		margin: rem-calc(40) auto;
	}
	&.large
	{
		margin: rem-calc(80) auto;
	}
}