.edit-entry,
.edit-entry:link,
.edit-entry:focus,
.edit-entry:visited
{
	position: fixed;
	display: block;
	right: 0;
	top: 40%;
	background-color: $hlp-deep-red;
	color: white;
	padding: 10px;
	font-weight: bold;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	opacity: 0.4;
	transition: 0.3s ease-in-out all;
	&:hover
	{
		color: white;
		opacity: 1;
	}
}