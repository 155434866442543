$slideshowHeight: 490;

// Individual Slide
.highlight,
.highlight.slick-slide
{
	background-size: cover;
	background-position: 50% 50%;
	height: rem-calc($slideshowHeight);
	padding: rem-calc(32);
	display: flex;
	flex-flow: col nowrap;
	align-items: center;
	justify-content: center;
	text-shadow: 0 0 15px rgba(black, 0.5);
	@include dimmed();
	figcaption
	{
		z-index: 1;
		flex: 1 1 auto;
		text-align: center;
		padding: rem-calc(16);
		background-color: rgba(black, 0.5);
		.title
		{
			font-weight: 300;
			p
			{
				margin: 0;
			}
			strong
			{
				font-weight: 900;
				letter-spacing: 0.075em;
			}
		}
		.button
		{
			text-transform: uppercase;
			font-size: rem-calc(18);
			letter-spacing: 0.05em;
		}
	}
	@include breakpoint(large)
	{
		&.position__h__
		{
			&left
			{
				justify-content: flex-start;
				text-align: left;
			}
			&center
			{
				justify-content: center;
			}
			&right
			{
				justify-content: flex-end;
				text-align: right;
			}
		}
		&.position__v__
		{
			&top
			{
				align-items: flex-start;
			}
			&middle
			{
				align-items: center;
			}
			&bottom
			{
				align-items: flex-end;
			}
		}
		figcaption
		{
			flex: 0 1 50%;
			padding: 0 rem-calc(32);
			background-color: transparent;
		}
	}
}

.hl-nav-wrapper
{
	background-color: $black;
	max-height: rem-calc($slideshowHeight);
	overflow-y: auto;
}
.hl-nav
{
	.hl-thumb
	{
		overflow: hidden;
		&:focus
		{
			outline: none;
		}
		&.slick-current
		{
			background-color: $dark-gray;
			img
			{
				border-color: $light-gray;
			}
		}
		img
		{
			border: 4px solid transparent;
		}
		figcaption
		{
			display: none;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			.title
			{
				font-size: rem-calc(16);
				margin: 0;
			}
		}
	}
	@include breakpoint(large)
	{
		.hl-thumb
		{
			border: 4px solid transparent;
			display: flex;
			flex-flow: row nowrap;
			&.slick-current
			{
				border-color: $light-gray;
			}
			img
			{
				border: none;
			}
			figcaption
			{
				display: block;
				margin-left: 5px;
			}
		}
	}
}