.section__intro
{
	text-align: center;
	background-color: $dark-gray;
	color: $white;
	padding: rem-calc(50);
	font-size: rem-calc(20);
	.contents
	{
		max-width: 32em;
		margin-left: auto;
		margin-right: auto;
	}
	h1
	{
		@include underlined-heading();
	}
	p + p
	{
		margin-top: 1.5em;
	}
	strong
	{
		font-weight: 900;
		letter-spacing: 0.025em;
	}
	@include breakpoint(medium)
	{
		font-size: rem-calc(30);
	}
}