// $corner-size: 10px;

.button
{
	transition: background-color 0.25s ease-out, color 0.25s ease-out, border-color 0.25s ease-out;
	// box-shadow: 10px 4px 0 red, -10px -4px 0 green;
	// transition: box-shadow ease 1s;
	&.effect_1
	{
		@include beveled-corners;
	}
	&.hollow,
	&.ghost
	{
		position: relative;
		border: 2px solid currentColor;
	}
	// &:hover
	// {
	// 	box-shadow: 1px 1px 0 red, -1px -1px 0 green;
	// }
}