blockquote
{
	border: none;
	&.left
	{
		text-align: left;
	}
	&.center
	{
		text-align: center;
	}
	&.right
	{
		text-align: right;
	}
	.quote-content
	{
		font-family: serif;
		font-style: italic;
		font-size: rem-calc(18);
		&:before
		{
			content: '\201C';
		}
		&:after
		{
			content: '\201D';
		}
	}

	cite
	{
		color: inherit;
	}


	// Pullquotes
	&.pullquote
	{
		color: $hlp-light-red;
		text-align: center;
		.quote-content
		{
			font-size: 2em;
			position: relative;
			&:before,
			&:after
			{
				position: absolute;
				z-index: 0;
				font-size: 2em;
				opacity: 0.3;
				line-height: 1;
			}
			&:before
			{
				top: 0;
				left: 0;
			}
			&:after
			{
				bottom: 0;
				right: 0;
				line-height: 0;
			}
		}
		&.right
		{
			float: right;
			// text-align: right;
			max-width: 40%;
		}
		&.left
		{
			float: left;
			// text-align: left;
			max-width: 40%;
		}
	}
}
