// Everything is responsible for the margin above it, not below it.
// So, margin-top: 50px; (for example), while margin-bottom: 0;.
@charset 'utf-8';

// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,900');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,900');

$primary-color: #AF4C5E;
$light-gray: #424242;
$medium-gray: #303030;
$dark-gray: #171819;
$black: #0a0a0a;
$white: #fefefe;
$hlp-light-red: #c15050;
$hlp-deep-red: #4b0c0c;
$body-background: $dark-gray;
$body-font-family: 'Lato', 'Helvetica Neue', Helvetica, Lato, Arial, sans-serif;

$shadow-key-umbra-opacity: 0.2;
$shadow-key-penumbra-opacity: 0.14;
$shadow-ambient-shadow-opacity: 0.12;

@font-face {
	font-family: 'BankGothic';
	src: url("/fonts/bankgthd-webfont.eot");
	src: local("?"),url("/fonts/bankgthd-webfont.woff") format("woff"),url("/fonts/bankgthd-webfont.ttf") format("truetype"),url("/fonts/bankgthd-webfont.svg#webfontfLocfqIY") format("svg");
	font-weight: normal;
	font-style: normal
}

@import 'settings';
@import 'foundation';
@import 'motion-ui';
// @import 'breakpoint';
@import 'slick.scss';
// @import 'slick-theme.scss';

@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
@include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
// @include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
@include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
// @include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;



@include motion-ui-transitions;
@include motion-ui-animations;

@import 'includes/helpers';
@import 'modules/globals';
@import 'modules/base';
@import 'modules/header';
@import 'modules/icons';
@import 'modules/buttons';
@import 'modules/edit-entry';
@import 'modules/blockquotes';
@import 'modules/slideshows';
@import 'modules/badges';
@import 'modules/breaks';
@import 'modules/images';
@import 'modules/hero';
@import 'modules/highlights';
@import 'modules/modular-content';
@import 'pages/front/front';
@import 'pages/give/give';
@import 'modules/footer';