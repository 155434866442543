.section__stats
{
	text-align: center;
	background-color: $dark-gray;
	color: $white;
	padding: rem-calc(50);
	font-size: rem-calc(20);
	h1
	{
		@include underlined-heading();
	}
	ul
	{
		list-style: none;
		margin-left: 0;
		display: flex;
		flex-flow: row wrap;
		li
		{
			flex: 1 1 0;
			padding: 0 rem-calc(20);
			.icon
			{
				fill: $hlp-light-red;
				width: rem-calc(80);
				height: auto;
				&-gamepad
				{
					width: rem-calc(100);
				}
			}
			.number
			{
				font-weight: bold;
				font-size: rem-calc(60);
			}
		}
	}
	@include breakpoint(640)
	{
		ul
		{
			flex-flow: row nowrap;
		}
	}
}