.slick-initialized
{
	&:hover
	{
		.slick-arrow
		{
			opacity: 0.5;
		}
	}
	&.slideshow
	{
		position: relative;
		&:before
		{
			position: absolute;
			top: 10px;
			right: rem-calc(60);
			opacity: 0;
			letter-spacing: 0.05em;
			font-size: rem-calc(14);
			line-height: 1;
			content: "\25b6 Resuming autoplay";
			transition: 0.5s ease all;
			transition-delay: 0.5s;
			z-index: 1;
			animation: 2s ease-in-out pulse infinite;
		}
		&:hover
		{
			&:before
			{
				content: "\23F8 Autoplay paused";
				opacity: 1;
			}
			.slick-arrow
			{
				opacity: 0.5;
			}
		}
	}
	&.slideshow-nav
	{
		.slick-track
		{
			padding-top: 18px;
		}
		.slick-slide
		{
			border: 3px solid transparent;
			&.slick-current
			{
				border-color: white;
				position: relative;
				&:before
				{
					content: "";
					display: block;
					position: absolute;
					width: 0;
					height: 0;
					border-bottom: 18px solid white;
					border-left: 16px solid transparent;
					border-right: 16px solid transparent;
					bottom: 100%;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}
	.slick-slide
	{
		&.section__hero
		{
			display: flex;
		}
	}
	.slick-arrow
	{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		height: 50px;
		opacity: 0;
		// background-color: rgba(black, 0.5);
		// width: rem-calc(50);
		width: 0;
		border-top: 25px solid transparent;
		border-bottom: 25px solid transparent;
		z-index: 1;
		transition: 0.5s ease all;
		font-size: 0;
		line-height: 0;
		cursor: pointer;
		// &:before
		// {
		// 	display: block;
		// 	position: absolute;
		// 	color: white;
		// 	font-size: rem-calc(32);
		// 	line-height: 1;
		// 	top: 50%;
		// 	transform: translateY(-50%);
		// }
		&:hover
		{
			opacity: 1;
		}
		&.slick-prev
		{
			left: rem-calc(16);
			border-right: 50px solid white;
			// &:before
			// {
			// 	content: "<";
			// 	left: 4px;
			// }
		}
		&.slick-next
		{
			right: rem-calc(16);
			border-left: 50px solid white;
			// &:before
			// {
			// 	content: ">";
			// 	right: 4px;
			// }
		}
	}
}

@keyframes pulse {
	0% { text-shadow: 0 0 0px rgba(white, 1); }
	50% { text-shadow: 0 0 10px rgba(white, 1); }
	100% { text-shadow: 0 0 20px rgba(white, 0.0); }
}