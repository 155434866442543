.block.text
{
	padding: rem-calc(50) 10% 0;
	max-width: 40rem;
	box-sizing: content-box;
	&.lead
	{
		font-size: rem-calc(22);
	}
	p
	{
		&:last-child
		{
			margin-bottom: 0;
		}
	}
}