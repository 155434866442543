.donation-tier
{
	text-align: center;
	padding: rem-calc(16);
	// background-color: $light-gray;
	background-color: black;
	border-radius: 1px;
	// border: 1px solid rgba(white, 0.015);
	// box-shadow: 0 2px 24px rgba($hlp-light-red, 0.5);
	color: white;
	@include shadow();
	margin: rem-calc(50) 0;
	// padding-top: rem-calc(5);
	.header
	{
		// margin: rem-calc(0 0 0);
		text-align: center;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		padding: 56.25% rem-calc(16 16);
		@include scrim();
	}
	.title
	{
		line-height: 1;
		color: white;
		font-size: rem-calc(24);
		// font-style: italic;
		font-weight: 300;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		text-shadow: 0 0 5px rgba(black, 0.75);
	}
	.amount
	{
		background-color: $hlp-deep-red;
		// color: $black;
		line-height: 1;
		font-size: rem-calc(32);
		margin: rem-calc(20) -16px 0;
		padding-top: rem-calc(20);
		border-top: 1px solid rgba($hlp-light-red, 0.35);
	}
	.receives
	{
		background-color: $hlp-deep-red;
		margin: 0 -16px rem-calc(20);
		color: white;
		font-size: rem-calc(17);
		// font-style: italic;
		font-weight: 300;
		padding-bottom: rem-calc(20);
		border-bottom: 1px solid rgba($hlp-light-red, 0.35);
		.badge
		{
			margin-left: rem-calc(5);
		}
	}
	.description
	{
		font-size: rem-calc(13);
		margin: rem-calc(20) 0;
		p:last-child
		{
			margin-bottom: 0;
		}
	}
	.actions
	{
		.button
		{
			margin-bottom: 0;
			font-size: rem-calc(25);
			font-family: $body-font-family;
		}
	}
}