@mixin beveled-corners($bevel-size: 10px, $tl: true, $tr: false, $br: true, $bl: false)
{
	$tl-clip: 0 0;
	$tr-clip: 100% 0;
	$br-clip: 100% 100%;
	$bl-clip: 0 100%;
	@if $tl == true
	{
		$tl-clip: 0 $bevel-size, $bevel-size 0;
	}
	@if $tr == true
	{
		$tr-clip: calc(100% - #{$bevel-size}) 0, 100% $bevel-size;
	}
	@if $br == true
	{
		$br-clip: 100% calc(100% - #{$bevel-size}), calc(100% - #{$bevel-size}) 100%;
	}
	@if $bl == true
	{
		$bl-clip: $bevel-size 100%, 0 calc(100% - #{$bevel-size});
	}

	clip-path: polygon(
		$tl-clip,
		$tr-clip,
		$br-clip,
		$bl-clip
	);
}

@mixin underlined-heading( $color: $white, $border-color: rgba($white, 0.5) )
{
	display: inline-block;
	border-bottom: 2px solid rgba($white, 0.5);
	padding: 0 0 0.5em;
	width: 20em;
	max-width: 100%;
	text-align: center;
	margin-bottom: 1.5em;
	line-height: 1.5;
	text-transform: uppercase;
	font-size: rem-calc(24);
	font-weight: bold;
	letter-spacing: 0.05em;
}

@mixin dimmed( $pseudo: 'before', $color: rgba(black, 0.2) )
{
	position: relative;
	&:#{$pseudo}
	{
		content: "";
		display: block;
		position: absolute;
		background-color: $color;
		top: 0;
		height: 100%;
		left: 0;
		width: 100%;
	}
	> *
	{
		z-index: 1;
	}
}

@mixin scrim( $gradient-dark-opacity: 0.3, $color: rgba(black, 0.2), $direction: to top )
{
	position: relative;
	&:before
	{
		content: "";
		display: block;
		position: absolute;
		background-image: linear-gradient($direction, rgba(#000, $gradient-dark-opacity) 0%, rgba(#000, $gradient-dark-opacity/2) 30%, rgba(#000, 0.0) 100%);
		background-size: 100% 50%;
		background-repeat: no-repeat;
		background-position: 50% 100%;
		top: 0;
		height: 100%;
		left: 0;
		width: 100%;
	}
	> *
	{
		z-index: 1;
	}
}

@mixin shadow() {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              0 3px 1px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              0 1px 5px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}