.section__hero
{
	background-size: cover;
	background-position: 50% 50%;
	height: rem-calc(450);
	padding: rem-calc(32) 0;
	display: flex;
	flex-flow: col nowrap;
	align-items: center;
	justify-content: center;
	text-shadow: 0 0 15px rgba(black, 0.5);
	@include dimmed();
	figcaption
	{
		z-index: 1;
		flex: 1 1 auto;
		text-align: center;
		padding: rem-calc(16);
		background-color: rgba(black, 0.5);
		h1
		{
			font-weight: 300;
			p
			{
				margin: 0;
			}
			strong
			{
				font-weight: 900;
				letter-spacing: 0.075em;
			}
		}
		.button
		{
			text-transform: uppercase;
			font-size: rem-calc(18);
			letter-spacing: 0.05em;
		}
	}
}
@include breakpoint(medium)
{
	.section__hero
	{
		&.position__
		{
			&center
			{
				align-items: center;
				justify-content: center;
			}
			&left
			{
				align-items: flex-start;
				justify-content: flex-start;
			}
			&right
			{
				align-items: flex-start;
				justify-content: flex-end;
			}
			&drop-left
			{
				align-items: flex-end;
				justify-content: flex-start;
			}
			&drop-right
			{
				align-items: flex-end;
				justify-content: flex-end;
			}
		}
		figcaption
		{
			flex: 0 1 50%;
			padding: 0 rem-calc(32);
			background-color: transparent;
		}
	}
}