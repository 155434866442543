.section__steps
{
	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
	justify-content: space-between;
	text-shadow: 0 0 15px rgba(black, 0.5);
	.step // figure
	{
		flex: 0 0 100%;
		background-size: cover;
		background-position: 50% 50%;
		padding: rem-calc(32 16);
		display: flex;
		align-items: center;
		justify-content: center;
		@include dimmed();
	}
	figcaption
	{
		text-align: center;
		text-shadow:
			0 0 10px rgba(black, 1),
			0 0 20px rgba(black, 1),
			0 0 30px rgba(black, 1);
		h2
		{
			font-weight: 700;
			font-size: rem-calc(36);
			p
			{
				margin: 0;
			}
			strong
			{
				font-weight: 900;
				letter-spacing: 0.075em;
			}
		}
		.button
		{
			text-transform: uppercase;
			font-size: rem-calc(18);
			letter-spacing: 0.05em;
			text-shadow: none;
		}
	}
}
@include breakpoint(medium)
{
	height: rem-calc(450);
	.section__steps
	{
		flex-flow: row nowrap;
		.step
		{
			flex: 0 0 50%;
			padding: rem-calc(32);
		}
	}
}