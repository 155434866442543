.no-image
{
	background-color: #888;
	color: #747474;
	text-align: center;
	height: 0;
	padding: 0 0 56.25%;
	position: relative;
	text-shadow: 1px 1px 0 rgba(white, 0.15);
	&.square
	{
		padding-bottom: 100%;
	}
	&:after
	{
		content: "No image";
		text-transform: uppercase;
		font-size: rem-calc(24);
		font-weight: 900;
		line-height: 100%;
		letter-spacing: 0.1em;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
}